:root {
  body {
    &.tonybet {
      &.dark {
        --cashierBackgorundColor: rgba(58, 61, 64, 1);
        --cashierInnerBackground: rgba(107, 107, 107, 1);
        --cashierTitleType: rgba(255, 255, 255, 1);
        --cashierLogoBoxShadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
        --cashierLogoBorder: #292526;
        --paymentIconHolderBackground: #FFF;
        --cashierTitleContainerBottomMargin: 45px;
        --cashierTitleTypeFontSize: 24px;
        --cashierTitleTypeMobileFontSize: 24px;
        --cashierTitleTypeFontWeight: 600;
        --cashierTitleTypeLineHeight: 30px;

        --cashierBalanceTextColor: rgba(255, 255, 255, 1);
        --cashierBalanceLineHeight: 140%;
        --cashierBalanceFontWeight: 700;

        --cashierCurrencyFontWeight: 700;
        --cashierCurrencyColor: rgba(233, 110, 50, 1);
        --cashierAmountErrorColor: #FF6161;
        --cashierAmountColor: rgba(233, 110, 50, 1);
        --cashierAmountBorderBottomColor: #4DA533;
        --cashierAmountFontSize: 48px;
        --cashierAmountFontWeight: 900;
        --cashierInputBackground: rgba(107, 107, 107, 1);
        --cashierInputBoxShadow: initial;
        --cashierInputFontSize: 14px;
        --cashierLabelText: rgba(121, 121, 121, 1);
        --cashierWithdrawalLabelText: rgba(255, 255, 255, 1);
        --cashierInputBorder: transparent;
        --cashierInputColor: #FFFAF3;
        --cashierInputPlaceholder: rgba(255, 255, 255, 1);
        --cashierInputActiveBorder: rgba(233, 110, 50, 1);
        --cashierInputActiveBackground: rgba(107, 107, 107, 1);
        --cashierInputBorderRadius: 32px;
        --cashierInputFontWeight: 400;
        --cashierInputErrorBackground: rgba(107, 107, 107, 1);
        --cashierInputErrorBorder: rgba(248, 78, 78, 1);

        --cashierButtonDisabled: rgba(195, 204, 217, 1);
        --cashierButtonDisabledText: rgba(255, 255, 255, 1);
        --cashierButtonDisabledOpacity: initial;
        --cashierButton: rgba(158, 43, 134, 1);
        --cashierButtonText: rgba(255, 255, 255, 1);
        --cashierButtonRadius: 24px;

        --cashierFinalScreenInstructionTextTransformation: initial;
        --cashierFinalScreenInstructionFontSize: 14px;
        --cashierFinalScreenInstruction: rgba(255, 255, 255, 1);
        --cashierFinalScreenPendingTextSize: 16px;
        --cashierFinalScreenPendingFontSize: 400;
        --cashierFinalScreenText: rgba(67, 182, 92, 1);
        --cashierFinalScreenPendingText: rgba(67, 182, 92, 1);
        --cashierFinalScreenTextSize: 20px;
        --cashierFinalScreenTextFontSize: 700;
        --cashierFinalScreenMobileTextSize: 14px;
        --cashierFinalScreenErrorText: rgba(248, 78, 78, 1);
        --cashierFinalScreenBackground: rgba(107, 107, 107, 1);
        --cashierConfirmationColor: rgba(255, 255, 255, 1);
        --cashierConfirmationTextColor: rgba(255, 255, 255, 1);
        --cashierConfirmationHeaderColor: rgba(255, 255, 255, 1);
        --cashierConfirmationLineHeight: 32px;
        --cashierConfirmationHeaderFontSize: 20px;
        --cashierConfirmationHeaderMobileFontSize: 20px;
        --cashierConfirmationHeaderFontWeight: 700;
        --cashierConfirmationInfoBackground: rgba(121, 121, 121, 1);
        --cashierConfirmationInputsBackground: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
        --cashierConfirmationInputsBorderColor: hsla(0,0%,100%,.2);
        --cashierConfirmationInputsCopyIcon: rgba(255, 255, 255, 1);
        --cashierFinalScreenSuccessImage: rgba(67, 182, 92, 1);
        --cashierFinalScreenFailureImage: rgba(248, 78, 78, 1);
        --cashierFinalScreenPendindImage: rgba(233, 110, 50, 1);

        --cashierFavoriteAmountTextShadow: 0 1px 1px rgba(0, 0, 0, .25);
        --cashierFavoriteAmountTextFontWeight: 600;
        --cashierFavoriteAmountColor: #fffaf3;
        --cashierFavoriteAmount: rgba(121, 121, 121, 1);
        --cashierFavoriteAmountHover: initial;
        --cashierFavoriteAmountSelected: rgba(233, 110, 50, 1);
        --cashierFavoriteAmountBorder: initial;
        --cashierFavoriteAmountBorderRadius: 4px;
        --cashierFavoriteAmountSelectedColor: rgba(255, 255, 255, 1);

        --cashierFavoriteAmountBonusBadgeBackgroundColor: rgba(242, 201, 76, 1);
        --cashierFavoriteAmountBonusBadgeColor: rgba(62, 64, 73, 1);

        --cashierCheckbox: rgba(67, 182, 92, 1);
        --cashierCheckboxFontSize: 10px;
        --cashierCheckboxFontWeight: 400;
        --cashierCheckboxLineHeight: 16px;
        --cashierCheckboxText: rgba(255, 255, 255, 1);
        --cashierCheckboxColor: rgba(121, 121, 121, 1);
        --cashierCheckboxMarkColor: #322E2F;
        --cashierCheckboxBoxShadow: 0 3px 1px rgba(29, 25, 26, 0.3), inset 0 1px 0 rgba(128, 128, 128, 0.4);

        --cashierErrorsColorFont: rgba(248, 78, 78, 1);
        --cashierErrorsBackground: linear-gradient(0deg, rgba(58, 61, 64, 0.75) 0%, rgba(58, 61, 64, 0.75) 100%), #F84E4E;
        --cashierErrorsBorder: rgba(248, 78, 78, 1);
        --cashierErrorsBorderRadius: 4px;

        --cashierDropdownLabel: rgba(121, 121, 121, 1);
        --cashierDropdownBoxShadow: initial;
        --cashierDropdownBackground: rgba(107, 107, 107, 1);
        --cashierDropdownColor: rgba(255, 255, 255, 1);
        --cashierDropdownOptionHover: rgba(121, 121, 121, 1);
        --cashierDropdownColorHover: initial;
        --cashierDropdownBorderRadius: 32px;
        --cashierDropdownActiveBorderColor: transparent;
        --cashierDropdownBorderRadiusCorner: 32px;
        --cashierDropdownOptionsTopMargin: 1px;
        --cashierDropdownOptionsBoxShadow: none;

        --cashierNewAccountBackground: rgba(107, 107, 107, 1);
        --cashierNewAccountBoxShadow: none;

        --cashierDescriptionText: rgba(255, 255, 255, 1);
        --cashierIconColor: rgba(255, 255, 255, 1);
        --cashierLoaderColor: rgba(233, 110, 50, 1);
        --cashierArrowWrapperDisplay: none;
        --cashierArrowIconColor: initial;

        --cashierConfirmationInputsSecondaryColor: #A5A6A8;
        --cashierFileUploadingIconDisplay: initial;
      }

      &.light {
        --cashierBackgorundColor: rgba(255, 255, 255, 1);
        --cashierInnerBackground: rgba(244, 244, 244, 1);
        --cashierTitleType: rgba(105,90,114);
        --cashierLogoBoxShadow: initial;
        --cashierLogoBorder: rgba(226, 232, 238, 1);
        --paymentIconHolderBackground: #FFF;
        --cashierTitleContainerBottomMargin: 45px;
        --cashierTitleTypeFontSize: 24px;
        --cashierTitleTypeMobileFontSize: 24px;
        --cashierTitleTypeFontWeight: 700;
        --cashierTitleTypeLineHeight: 32px;

        --cashierBalanceTextColor: rgba(140, 129, 142, 1);
        --cashierBalanceLineHeight: 20px;
        --cashierBalanceFontWeight: 400;

        --cashierCurrencyFontWeight: 900;
        --cashierCurrencyColor: rgba(235, 83, 40, 1);
        --cashierAmountErrorColor: rgba(245, 71, 68, 1);
        --cashierAmountColor: rgba(105,90,114);
        --cashierAmountBorderBottomColor: rgba(245, 71, 68, 1);
        --cashierAmountFontSize: 48px;
        --cashierAmountFontWeight: 900;
        --cashierInputBackground: rgba(255, 255, 255, 1);
        --cashierInputBoxShadow: initial;
        --cashierInputFontSize: 14px;
        --cashierLabelText: rgba(140,129,142);

        --cashierWithdrawalLabelText: rgba(140, 129, 142, 1); // ??

        --cashierInputBorder: rgba(229, 229, 229, 1);
        --cashierInputColor: rgba(62, 64, 73, 1);
        --cashierInputPlaceholder: rgba(62, 64, 73, .5);
        --cashierInputActiveBorder: rgba(229, 229, 229, 1);
        --cashierInputActiveBackground: rgba(255, 255, 255, 1);
        --cashierInputBorderRadius: 8px;
        --cashierInputFontWeight: 700;
        --cashierInputErrorBackground: rgba(255, 255, 255, 1);
        --cashierInputErrorBorder: rgba(240, 78, 52, 1);

        --cashierButtonDisabled: rgba(212, 212, 212, 1);
        --cashierButtonDisabledText: rgba(255, 255, 255, 1);
        --cashierButtonDisabledOpacity: initial;
        --cashierButton: rgba(157, 32, 255, 1);
        --cashierButtonText: rgba(255, 255, 255, 1);
        --cashierButtonRadius: 60px;

        --cashierFinalScreenInstructionTextTransformation: initial;
        --cashierFinalScreenInstructionFontSize: 14px;
        --cashierFinalScreenInstruction: rgba(140, 129, 142, 1);
        --cashierFinalScreenPendingTextSize: 16px;
        --cashierFinalScreenPendingFontSize: 400;
        --cashierFinalScreenText: rgba(67, 182, 92, 1);
        --cashierFinalScreenPendingText: rgba(235, 83, 40, 1);
        --cashierFinalScreenTextSize: 20px;
        --cashierFinalScreenTextFontSize: 700;
        --cashierFinalScreenMobileTextSize: 20px;
        --cashierFinalScreenErrorText: rgba(245, 71, 68, 1);
        --cashierFinalScreenBackground: rgba(255, 255, 255, 1);
        --cashierConfirmationColor: rgba(140, 129, 142, 1);
        --cashierConfirmationTextColor: rgba(105,90,114);
        --cashierConfirmationHeaderColor: rgba(140,129,142);
        --cashierConfirmationLineHeight: 32px;
        --cashierConfirmationHeaderFontSize: 20px;
        --cashierConfirmationHeaderMobileFontSize: 20px;
        --cashierConfirmationHeaderFontWeight: 700;
        --cashierConfirmationInfoBackground: rgba(244, 244, 244, 1);
        --cashierConfirmationInputsBackground: rgba(244, 244, 244, 1);
        --cashierConfirmationInputsBorderColor: rgba(229, 229, 229, 1);
        --cashierConfirmationInputsCopyIcon: rgba(140, 129, 142, 1);
        --cashierFinalScreenSuccessImage: rgba(67, 182, 92, 1);
        --cashierFinalScreenFailureImage: rgba(245, 71, 68, 1);
        --cashierFinalScreenPendindImage: rgba(235, 83, 40, 1);

        --cashierFavoriteAmountTextShadow: initial;
        --cashierFavoriteAmountTextFontWeight: 700;
        --cashierFavoriteAmountColor: rgba(62, 64, 73, 1);
        --cashierFavoriteAmount: rgba(229, 229, 229, 1);
        --cashierFavoriteAmountHover: initial;
        --cashierFavoriteAmountSelected: rgba(235, 83, 40, 1);
        --cashierFavoriteAmountBorder: initial;
        --cashierFavoriteAmountBorderRadius: 4px;
        --cashierFavoriteAmountSelectedColor: rgba(255, 255, 255, 1);

        --cashierCheckbox: rgba(67, 182, 92, 1);
        --cashierCheckboxFontSize: 12px;
        --cashierCheckboxFontWeight: 400;
        --cashierCheckboxLineHeight: 16px;
        --cashierCheckboxText: rgba(121, 121, 121, 1);
        --cashierCheckboxColor: #e5e5e5;
        --cashierCheckboxMarkColor: rgba(255, 255, 255, 1);
        --cashierCheckboxBoxShadow: rgba(121, 121, 121, 1);

        --cashierErrorsColorFont: rgba(245, 71, 68, 1);
        --cashierErrorsBackground: rgba(248, 78, 78, 0.10);
        --cashierErrorsBorder: initial;
        --cashierErrorsBorderRadius: 4px;

        --cashierDropdownLabel: rgba(140, 129, 142, 1);
        --cashierDropdownBoxShadow: initial;
        --cashierDropdownBackground: rgba(255, 255, 255, 1);
        --cashierDropdownColor: rgba(62, 64, 73, 1);
        --cashierDropdownOptionHover: rgba(244, 244, 244, 1);
        --cashierDropdownColorHover: initial;
        --cashierDropdownBorderRadius: 8px;
        --cashierDropdownActiveBorderColor: rgba(77, 165, 51, 1);
        --cashierDropdownBorderRadiusCorner: 8px;
        --cashierDropdownOptionsTopMargin: 1px;
        --cashierDropdownOptionsBoxShadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.15);
        --cashierDropdownPlaceholder: rgba(62, 64, 73, .5);

        --cashierNewAccountBackground: rgba(229, 229, 229, 1);
        --cashierNewAccountBoxShadow: none;

        --cashierDescriptionText: rgba(62, 64, 73, 1);
        --cashierIconColor: rgba(62, 64, 73, 1);
        --cashierLoaderColor: rgba(235, 83, 40, 1);
        --cashierArrowWrapperDisplay: none;
        --cashierArrowIconColor: initial;

        --cashierConfirmationInputsSecondaryColor: rgba(140, 129, 142, 1);
        --cashierFileUploadingIconDisplay: initial;
      }
    }
  }
}
